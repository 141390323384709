import { Form, FloatingLabel } from "react-bootstrap";

const FormInput = ({ label, controlID, ...inputOptions }) => {
  return (
    <>
      <FloatingLabel label={label} controlId={controlID} className="mb-3">
        <Form.Control {...inputOptions} />
      </FloatingLabel>
    </>
  );
};

export default FormInput;
