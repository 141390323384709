import { useState } from "react";
import { Alert, Form, Button } from "react-bootstrap";
import { resetPassword } from "../../utils/firebase/firebase.utils";
import FormInput from "../form-input/form-input.component";
import { Link } from "react-router-dom";

const defaultFormFields = {
  email: "",
};

const ForgotPasswordForm = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { email } = formFields;
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  console.log(formFields);

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setMessage("");
      setError("");
      setLoading(true);
      await resetPassword(email);
      resetFormFields();
      setMessage(`Check ${email} inbox for further instructions`);
    } catch (error) {
      console.log("user encountered an error", error);
      setError("Failed to reset password");
    }
    setLoading(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <div className="col-lg-6 mx-auto">
      {error && <Alert variant="danger">{error}</Alert>}
      {message && <Alert variant="success">{message}</Alert>}
      <Form
        className="p-4 p-md-5 border rounded-3 bg-light"
        onSubmit={handleSubmit}
      >
        <FormInput
          label="Email address"
          controlID="floatingEmail"
          type="email"
          placeholder="name@example.com"
          required
          onChange={handleChange}
          name="email"
          value={email}
        />
        <Button
          disabled={loading}
          className="w-100 btn btn-lg btn-primary"
          type="submit"
        >
          Request Password Reset
        </Button>
        <hr className="my-4" />
        <small className="text-muted">
          Back to
          <Link
            className="nav-link ps-1 fw-bold"
            to="/sign-in"
            style={{ display: "inline" }}
          >
            Sign-in
          </Link>
        </small>
      </Form>
    </div>
  );
};

export default ForgotPasswordForm;
