import { useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import FormInput from '../form-input/form-input.component';
import { Link, useNavigate } from 'react-router-dom';
import {
  createAuthUserWithEmailAndPassword,
  createUserDocumentFromAuth,
  sendAuthEmailVerification,
  writeUserData,
} from '../../utils/firebase/firebase.utils';
import { v4 as uuidv4 } from 'uuid';

const defaultFormFields = {
  email: '',
  password: '',
  company: '',
};

const SignUpForm = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { email, password, company } = formFields;
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  console.log(formFields);

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setError('');
      setLoading(true);
      const { user } = await createAuthUserWithEmailAndPassword(
        email,
        password
      );

      await writeUserData(user.uid, company, email);
      await createUserDocumentFromAuth(user, {
        activated: false,
        id: uuidv4().toUpperCase(),
        introductoryOfferStartDate: null,
        trialStartDate: null,
      });
      await sendAuthEmailVerification();
      resetFormFields();
      navigate('/verify-email');
    } catch (error) {
      console.log('user creation encountered an error', error);
      switch (error.code) {
        case 'auth/email-already-in-use':
          setError(
            'The provided email is already in use by an existing user. '
          );
          break;
        default:
          setError(
            `Failed to create an account. ${error.message.replace(
              'Firebase: ',
              ''
            )}`
          );
      }
    }
    setLoading(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <div className="col-lg-6 mx-auto">
      {error && <Alert variant="danger">{error}</Alert>}
      <Form
        className="p-4 p-md-5 border rounded-3 bg-light"
        onSubmit={handleSubmit}
      >
        <FormInput
          label="Email address"
          controlID="floatingEmail"
          type="email"
          placeholder="name@example.com"
          required
          onChange={handleChange}
          name="email"
          value={email}
        />
        <FormInput
          label="Password"
          controlID="floatingPassword"
          type="password"
          placeholder="Password"
          required
          onChange={handleChange}
          name="password"
          value={password}
        />
        <FormInput
          label="Company"
          controlID="floatingCompany"
          type="text"
          placeholder="My Company Name"
          required
          onChange={handleChange}
          name="company"
          value={company}
        />
        <Button
          disabled={loading}
          className="w-100 btn btn-lg btn-primary"
          type="submit"
        >
          Sign Up
        </Button>
        <hr className="my-4" />
        <small className="text-muted">
          By clicking Sign up, you agree to the{' '}
          <a
            href="https://helitrends.com/terms-of-use-mobile.html"
            className="link-secondary"
            target="_blank"
            rel="noopener noreferrer"
          >
            terms of use{' '}
          </a>
          and{' '}
          <a
            href="https://helitrends.com/privacy-policy-mobile.html"
            className="link-secondary"
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy policy
          </a>
          .
        </small>
        <div>
          <small className="text-muted">
            Already have an account?
            <Link
              className="nav-link ps-1 fw-bold"
              to="/sign-in"
              style={{ display: 'inline' }}
            >
              Sign-in
            </Link>
          </small>
        </div>
      </Form>
    </div>
  );
};

export default SignUpForm;
