import { Fragment } from 'react';
import { MdOutlineTimer } from 'react-icons/md';
import './contact.styles.scss';
import { Button } from 'react-bootstrap';

const Contact = () => {
  return (
    <Fragment>
      <div className="px-4 py-5 text-center">
        <div className="icon-square text-bg-business d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 mb-3">
          {<MdOutlineTimer />}
        </div>
        <h3 className="fw-bold">Activate your subscription</h3>
        <p className="text-muted mb-0">
          Your free trial has <strong>expired</strong>, but it's easy to get
          connected again. Or do you want <strong>upgrade</strong> your
          subscription plan?
        </p>
        <p className="text-muted">If you have any question - contact us.</p>
        <Button
          className="mt-2"
          variant="primary btn-lg"
          as="a"
          href="https://us10.list-manage.com/contact-form?u=f432ffee1057aec4ec02fb509&form_id=44ebd4a1ccf2c33ef3be44f244a248da"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact form
        </Button>
      </div>
    </Fragment>
  );
};

export default Contact;
