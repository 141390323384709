import { useState } from 'react';
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function AdminBusiness() {
  const [loading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // setLoading(true);
    console.log('Success');
  };

  return (
    <Container className="px-4">
      <div className="px-4 py-2 my-5">
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicHelicopters">
            <Form.Label>Number of helicopters</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter number of helicopters"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicBusinessName">
            <Form.Label>Team Name</Form.Label>
            <Form.Control type="text" placeholder="Enter team name" />
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            onClick={handleSubmit}
            disabled={loading}
          >
            Submit
          </Button>
        </Form>
      </div>
    </Container>
  );
}

export default AdminBusiness;
