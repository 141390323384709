import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './routes/home/home.component';
import Navigation from './routes/navigation/navigation.component';
import SignIn from './routes/sign-in/sign-in.component';
import SignUp from './routes/sign-up/sign-up.component';
import './App.scss';
import Checkout from './routes/checkout/checkout.component';
import PrivateRoute from './routes/private/privateRoute.component';
import ForgotPassword from './routes/forgot-password/forgot-password.component';
import VerifyEmail from './routes/verify-email/verify-email.component';
import Success from './routes/payments/success.component';
// import Account from './routes/account/account.component';
// import Users from './routes/users/users.component';
// import NewUser from './routes/users/new-user.component';
import RejectBusiness from './routes/users/reject-business.component';
import AdminRoute from './routes/private/adminRoute.component';
import AdminBusiness from './routes/admin-business/admin-business.component';
import Contact from './components/contact/contact.component';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigation />}>
        <Route index element={<Home />} />
        <Route
          path="/checkout"
          element={
            <PrivateRoute>
              <Checkout />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/account"
          element={
            <PrivateRoute>
              <Account />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/success"
          element={
            <PrivateRoute>
              <Success />
            </PrivateRoute>
          }
        />
        {
          /* <Route
          path="/users"
          element={
            <PrivateRoute>
              <Users />
            </PrivateRoute>
          }
        />
        <Route
          path="/newuser"
          element={
            <PrivateRoute>
              <NewUser />
            </PrivateRoute>
          }
        /> */
          <Route
            path="/adminbusiness"
            element={
              <AdminRoute>
                <AdminBusiness />
              </AdminRoute>
            }
          />
        }
        <Route path="/reject/:token" element={<RejectBusiness />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/contact" element={<Contact />} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default App;
