import { Fragment } from 'react';
import { Container } from 'react-bootstrap';
import FeatureSection from '../../components/feature/feature-section.component';
import Footer from '../../components/footer/footer.component';
import Hero from '../../components/hero/hero.component';
import Screens from '../../components/screens/screens.component';
import Trends from '../../components/trends/trends.component';
import Pricing from '../pricing/pricing.component';
import Contact from '../../components/contact/contact.component';

const Home = () => {
  return (
    <Fragment>
      <Container className="px-4 pb-5">
        <Hero />
        <Screens />
        <Trends />
        <Pricing />
        <FeatureSection />
        <Contact />
        <Footer />
      </Container>
    </Fragment>
  );
};

export default Home;
