import { initializeApp } from 'firebase/app';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  sendEmailVerification,
} from 'firebase/auth';
import {
  doc,
  getDoc,
  setDoc,
  getFirestore,
  updateDoc,
  addDoc,
  collection,
  where,
  query,
  getDocs,
  arrayRemove,
} from 'firebase/firestore';
import { child, get, getDatabase, ref, set } from 'firebase/database';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore();
export const realtimeDb = getDatabase(app);

//AUTH
export const createAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  return await createUserWithEmailAndPassword(auth, email, password);
};

export const signInAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  return await signInWithEmailAndPassword(auth, email, password);
};

export const signOutUser = async () => await signOut(auth);

export const onAuthStateChangedListener = (callback) =>
  onAuthStateChanged(auth, callback);

export const resetPassword = async (email) => {
  await sendPasswordResetEmail(auth, email);
};

export const sendAuthEmailVerification = async () => {
  await sendEmailVerification(auth.currentUser);
};

//FIRESTORE
export const createUserDocumentFromAuth = async (
  userAuth,
  additionalInformation = {}
) => {
  const userDocRef = doc(db, 'data', userAuth.uid);
  const userSnaphot = await getDoc(userDocRef);

  if (!userSnaphot.exists()) {
    // const { email, company } = userAuth;
    const createdAt = new Date();

    try {
      await setDoc(userDocRef, {
        createdAt,
        ...additionalInformation,
      });
    } catch (error) {
      console.log('error creating the user', error.message);
    }
  }

  return userDocRef;
};

export const createBusinessDocumentFromAuth = async (
  additionalInformation = {}
) => {
  const createdAt = new Date();
  const userData = await getUserData();
  await addDoc(collection(db, 'business'), {
    admin: { email: auth.currentUser.email, id: auth.currentUser.uid },
    users: [{ email: auth.currentUser.email }],
    createdAt,
    shortName: userData.company,
    ...additionalInformation,
  });
};

export const updateUserAuthEmailVerification = async () => {
  await updateDoc(doc(db, 'data', auth.currentUser.uid), {
    activated: true,
  });
};

export const getBusinessWhereIsAdmin = async () => {
  let business = null;
  const businessRef = collection(db, 'business');
  const q = query(
    businessRef,
    where('admin.email', '==', auth.currentUser.email)
  );
  const querySnap = await getDocs(q);
  querySnap.forEach((doc) => {
    business = { id: doc.id, data: doc.data() };
    // console.log(business);
  });
  return business;
};

export const userBelongsToAnotherBusiness = async () => {
  let business = null;
  const querySnapshot = await getDocs(collection(db, 'business'));
  querySnapshot.forEach((doc) => {
    for (let user of doc.data().users) {
      if (user.email === auth.currentUser.email) {
        business = { id: doc.id, data: doc.data() };
        // console.log(business);
        break;
      }
    }
  });
  return business;
};

export const getTokenWhereUser = async (docID, userEmail) => {
  let token = null;
  const businessRef = doc(db, 'business', docID);
  const docSnap = await getDoc(businessRef);
  docSnap.data().usersToken.forEach((userToken) => {
    if (userToken.email === userEmail) {
      token = userToken.token;
    }
  });
  return token;
};

export const removeUserFromBusiness = async (docID, user) => {
  const businessRef = doc(db, 'business', docID);
  const token = await getTokenWhereUser(docID, user.email);

  if (token) {
    await updateDoc(businessRef, {
      users: arrayRemove(user),
    });
    await updateDoc(businessRef, {
      usersToken: arrayRemove({ email: user.email, token }),
    });
  }
};

//REALTIME DATABASE
export const writeUserData = async (userID, company, email) => {
  await set(ref(realtimeDb, 'users/' + userID), {
    company: company,
    email: email,
  });
};

export const getUserData = async () => {
  const userID = auth.currentUser.uid;
  const snapshot = await get(child(ref(realtimeDb), `users/${userID}`));
  if (snapshot.exists()) {
    return snapshot.val();
  }
};
