import { Button, Card, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const PricingCard = (props) => {
  const featureList = [];
  props.features?.forEach((feature, index) => {
    featureList.push(<ListGroup.Item key={index}>{feature}</ListGroup.Item>);
  });

  return (
    <Card
      className="mb-4 rounded-3 shadow-sm"
      border={props.primary ? 'primary' : ''}
    >
      <Card.Header
        as="h5"
        className={props.primary ? 'py-3 text-bg-primary' : 'py-3'}
      >
        {props.header}
      </Card.Header>
      <Card.Body>
        <Card.Title as="h2" className="pricing-card-title">
          {props.discount ? (
            <span>
              <s className="text-muted">
                <small>{props.price} </small>
              </s>
              <span className="text-success">{props.priceDiscounted}</span>
            </span>
          ) : (
            <span>{props.price}</span>
          )}
          <small className="text-body-secondary fw-light"> USD /mo</small>
        </Card.Title>
        <div>
          <p className="text-muted">
            {props.primary
              ? 'per helicopter in your organization'
              : 'for personal use'}
            <br />
            {props.paidYearly ? 'billed yearly' : 'billed monthly'}
          </p>
          <ListGroup className="mt-3 mb-4">{featureList}</ListGroup>
        </div>

        {props.primary ? (
          <Button
            className="w-100"
            variant="primary"
            as={Link}
            href=""
            to={props.href}
            target=""
          >
            {props.buttonText}
          </Button>
        ) : (
          <Button
            className="w-100"
            variant="outline-secondary"
            as="a"
            href={props.href}
            to=""
            target="_blank"
          >
            {props.buttonText}
          </Button>
        )}
      </Card.Body>
    </Card>
  );
};

export default PricingCard;
