import { Container } from "react-bootstrap";
import ForgotPasswordForm from "../../components/forgot-password/forgot-password-form.component";

const ForgotPassword = () => {
  return (
    <Container className="px-4">
      <div className="px-4 py-5 my-5 text-center">
        <h1 className="display-4 fw-bold lh-1">Forgot your password?</h1>
        <div className="col-lg-8 mx-auto">
          <p className="lead mb-4">
            Please enter the email you use to sign in to Helitrends
          </p>
        </div>
        <ForgotPasswordForm />
      </div>
    </Container>
  );
};

export default ForgotPassword;
