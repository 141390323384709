import { Fragment } from 'react';
import Feature from './feature.component';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { BsGearFill, BsCreditCard } from 'react-icons/bs';
import { HiOutlineDevicePhoneMobile } from 'react-icons/hi2';

const FeatureSection = () => {
  return (
    <Fragment>
      <div className="row align-items-md-center g-5 py-5 border-bottom mx-0">
        <div className="col">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 g-4">
            <Feature
              icon={<HiOutlineDevicePhoneMobile />}
              title="Unlimited devices"
              par="Available for unlimited users and devices under your team account, access for your entire team."
            />
            <Feature
              icon={<AiOutlineUserAdd />}
              title="Unlimited users"
              par="As your organization grows, you won't face any roadblocks when it comes to inviting new users."
            />
            <Feature
              icon={<BsCreditCard />}
              title="Seamless collaboration"
              par="Paying for all users ensures that every team member, regardless of their role, can actively contribute to growth."
            />
            <Feature
              icon={<BsGearFill />}
              title="Manage helicopter fleet"
              par="Monitor the condition of your entire helicopter fleet in the one tool."
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FeatureSection;
