import axios from 'axios';
import { useState } from 'react';
import { Alert, Button, Container } from 'react-bootstrap';
import { MdOutlineUnsubscribe } from 'react-icons/md';
import { useParams } from 'react-router-dom';

const RejectBusiness = () => {
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { token } = useParams();

  const handleRejection = () => {
    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/business/reject/${token}`)
      .then((res) => {
        setLoading(false);
        setSuccess(
          `You are no longer a member of the team account: ${res.data.businessName}`
        );
        setError('');
      })
      .catch((err) => {
        setLoading(false);
        setError(
          'Team account invitation rejection error. Please contact to contact@helitrends.com'
        );
        setSuccess('');
        console.error(err.code);
      });
  };

  return (
    <Container className="px-4">
      <div className="px-4 py-5 text-center">
        {error && (
          <Alert variant="danger" className="col-lg-8 mx-auto">
            {error}
          </Alert>
        )}
        {success && (
          <Alert variant="success" className="col-lg-8 mx-auto">
            {success}
          </Alert>
        )}
        <h1 className="display-3">
          <MdOutlineUnsubscribe />
        </h1>
        <h1 className="display-6 fw-bold lh-1 mb-4">
          Are you sure about rejecting the invitation?
        </h1>
        <div className="col-lg-8 mx-auto">
          <p className="lead mb-4">
            If you reject now, you will stop using team account you were invited
            to.
          </p>
          <Button
            className="btn btn-lg btn-primary"
            disabled={loading}
            onClick={handleRejection}
          >
            Reject access to team mode
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default RejectBusiness;
