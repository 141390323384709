import { useContext, useState, useEffect } from 'react';
import { Container, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/user.context';
import {
  sendAuthEmailVerification,
  updateUserAuthEmailVerification,
} from '../../utils/firebase/firebase.utils';

const VerifyEmail = () => {
  const { currentUser } = useContext(UserContext);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        await currentUser?.reload();
        if (currentUser?.emailVerified) {
          clearInterval(interval);
          await updateUserAuthEmailVerification();
          navigate('/checkout');
        }
      } catch (error) {}
    }, 1000);
  }, [navigate, currentUser]);

  const resendEmailVerification = async () => {
    try {
      setLoading(true);
      await sendAuthEmailVerification();
    } catch (error) {
      console.log('user encountered an error', error);
      setError('Mail has already been sent. Wait a minute to resend.');
    }
    setLoading(false);
  };

  return (
    <Container className="px-4">
      <div className="px-4 py-5 my-5 text-center">
        <h1 className="display-4 fw-bold lh-1 mb-4">
          Verify your Email Address
        </h1>
        <div className="col-lg-8 mx-auto">
          <p className="lead mb-2">
            A verification email has been sent to:{' '}
            <span className="fw-bold">{currentUser?.email}</span>
          </p>
          <p className="lead mb-2">
            Follow the instruction in the email to verify your account.
          </p>
          <p className="lead mb-4">
            If there is no email, please check your spam folder.
          </p>

          {error && <Alert variant="danger">{error}</Alert>}
          <Button
            className="btn btn-lg btn-primary"
            disabled={!currentUser || loading || currentUser?.emailVerified}
            onClick={resendEmailVerification}
          >
            Resend Email
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default VerifyEmail;
