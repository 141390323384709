import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Alert, Button, Container } from 'react-bootstrap';
import { useState } from 'react';

const Success = () => {
  const [searchParams] = useSearchParams();
  const errParam = searchParams.get('err');
  const [error, setError] = useState('');

  useEffect(() => {
    if (errParam === '1') {
      setError(
        'Failed to retrieve a subscription. Please contact to contact@helitrends.com'
      );
    }

    return () => {
      <Container className="px-5 pb-5 my-5 border-bottom">
        <h3 className="fw-bold">Loading...</h3>
      </Container>;
    };
  }, [errParam]);

  return (
    <Container className="p-5 my-4 mb-4 bg-light rounded-3">
      {error && (
        <Alert variant="danger" className="mx-4">
          {error}
        </Alert>
      )}
      <div className="container-fluid py-5">
        <h1 className="display-5 fw-bold">Payment successful</h1>
        <p className="col-md-8 fs-4">Thank you for choosing Helitrends.</p>
        <ul className="fs-4">
          <li>Your company account is activated</li>
          <li>
            Invite every users who need to have access to your company account:{' '}
            <Link to="/users">Users Page</Link>
          </li>
          <li>
            Calculate and monitor helicopter performance in real-time with the
            Helitrends mobile app:{' '}
            <a
              href="https://apps.apple.com/app/id1471892720"
              target="_blank"
              rel="noopener noreferrer"
            >
              App Store
            </a>
          </li>
          <li>
            Manage your company account, subscription and users:{' '}
            <Link to="/account">Account Page</Link>
          </li>
        </ul>
        <Button
          variant="primary"
          size="lg"
          as={Link}
          to="/users"
          className="mt-2"
        >
          Configure users
        </Button>
      </div>
    </Container>
  );
};

export default Success;
