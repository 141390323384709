import { Fragment } from 'react';
import trendsMockup from '../../assets/trends-mockup.png';
import {
  TbSquareRoundedNumber1,
  TbSquareRoundedNumber2,
  TbSquareRoundedNumber3,
} from 'react-icons/tb';
import { HiOutlineLightBulb } from 'react-icons/hi';
import './trends.styles.scss';

const Trends = () => {
  return (
    <Fragment>
      <div
        className="row row-cols-1 row-cols-md-2 align-items-md-center g-5 py-5 border-bottom mx-0"
        id="trend"
      >
        <div className="col">
          <div className="row row-cols-1 g-4">
            <h5>
              Only for <span className="business-label">TEAMS</span>
            </h5>
            <h3 className="fw-bold">Engine Condition Trend Monitoring</h3>
            <p className="text-muted">
              The Helitrends app monitors the engine performance that are
              registered during power checks, to better understand changes in
              the engine’s condition.
            </p>
            <p className="text-muted my-0">
              <span className="h3 business-color">
                <TbSquareRoundedNumber1 />
              </span>{' '}
              The whole process of ECTM can be performed without any additional
              technical know-how because this is implemented in the software.
            </p>
            <p className="text-muted my-1">
              <span className="h3 business-color">
                <TbSquareRoundedNumber2 />
              </span>{' '}
              Real-time Engine Health Monitoring: monitor your engines condition
              in real-time, enabling proactive maintenance and maximizing
              performance.
            </p>
            <p className="text-muted my-1">
              <span className="h3 business-color">
                <TbSquareRoundedNumber3 />
              </span>{' '}
              Engine Usage Calculation: automatically calculate engine usage
              based on historical data, providing you with valuable insights
              into engine wear and optimizing maintenance schedules.
            </p>

            <h5>Need extended technical support?</h5>

            <p className="text-muted my-1">
              <span className="h3 business-color">
                <HiOutlineLightBulb />
              </span>{' '}
              Technical consulting service regarding the engines of your
              helicopter fleet.
            </p>
            <p className="text-muted my-1">
              <span className="h3 business-color">
                <HiOutlineLightBulb />
              </span>{' '}
              Fulfilling your authority's requirements? Order a customized
              report tailored to your needs.
            </p>
          </div>
        </div>
        <div className="col text-center">
          {/* <div className="row row-cols-1 g-4"> */}
          <img
            src={trendsMockup}
            alt="Mockup with trends screen, available only for Helitrends Teams"
            className="img-fluid"
            width="300"
            loading="lazy"
          />
          {/* </div> */}
        </div>
      </div>
    </Fragment>
  );
};

export default Trends;
