import { BsLinkedin, BsFacebook, BsApple } from 'react-icons/bs';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
      <div className="col-md-4 d-flex align-items-center">
        <span className="mb-3 mb-md-0 text-muted">
          © {currentYear} Helitrends, Sp. z o. o.
        </span>
      </div>
      <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
        <li className="ms-3">
          <a
            href="https://www.linkedin.com/company/helitrends"
            className="text-muted"
          >
            <BsLinkedin style={{ width: 24, height: 24 }} />
          </a>
        </li>
        <li className="ms-3">
          <a href="https://www.facebook.com/helitrends/" className="text-muted">
            <BsFacebook style={{ width: 24, height: 24 }} />
          </a>
        </li>
        <li className="ms-3">
          <a
            href="https://apps.apple.com/app/id1471892720"
            className="text-muted"
          >
            <BsApple style={{ width: 24, height: 24 }} />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
