import { Button } from 'react-bootstrap';
import mainScreen from '../../assets/app-screen-business.png';
import './hero.styles.scss';
import { Link } from 'react-router-dom';
import appStoreBadge from '../../assets/appstore-badge.png';

const Hero = () => {
  return (
    <div className="px-4 pt-5 mt-5 text-center border-bottom">
      <h1 className="fw-bold">Take helicopter performance to a higher level</h1>
      <div className="col-lg-8 mx-auto mb-4">
        <p className="lead mb-4">
          With Helitrends Teams, you can work together seamlessly. Share your
          results and optimize your helicopter’s performance. It allows you to
          perform engine power checks in under a minute and monitor engine
          condition in real-time.
        </p>
        <Button variant="primary btn-lg" as={Link} to="/checkout">
          Get Started
        </Button>
        <a
          href="https://apps.apple.com/app/id1471892720"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={appStoreBadge}
            alt="App Store Badge links to Helitrends app"
            className="mx-3 my-2 appStoreBadge"
            height="48"
            loading="lazy"
          />
        </a>
      </div>
      <div
        className="overflow-hidden img-container"
        // style={{ maxHeight: '200px' }}
      >
        <div className="container px-5">
          <img
            src={mainScreen}
            alt="mockup"
            className="img-fluid mb-4"
            width="320"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
