import { Fragment, useContext } from 'react';
import { ButtonGroup, Col, ToggleButton } from 'react-bootstrap';
import PricingCard from '../../components/pricing/pricing-card.component';
import { ProductsContext } from '../../context/product.context';
import { valueMonthly, valueMonthlyDiscounted } from '../../utils/price.utils';
import './pricing.styles.scss';

const Pricing = () => {
  const { products, setProduct } = useContext(ProductsContext);
  const {
    // amount,
    paidYearly,
    priceMonthly,
    priceYearly,
    // priceYearlyDiscounted,
    // priceMonthlyDiscounted,
    coupon_percent_off,
  } = products;

  const togglePlan = () => {
    setProduct({ ...products, paidYearly: !paidYearly });
  };

  return (
    <Fragment>
      <div className="px-4 py-5 text-left border-bottom" id="pricing">
        <h3 className="fw-bold">Pricing</h3>
        <p className="text-muted">
          Our teams pricing is based on the number of helicopters you add to the
          platform, ensuring that you only pay for what you use.
        </p>
        <div className="py-4 text-center">
          <ButtonGroup>
            <ToggleButton
              id="radio-monthly"
              type="radio"
              variant="outline-primary"
              name="radio"
              checked={!paidYearly}
              onChange={togglePlan}
            >
              Billed monthly
            </ToggleButton>
            <ToggleButton
              id="radio-yearly"
              type="radio"
              variant="outline-primary"
              name="radio"
              checked={paidYearly}
              onChange={togglePlan}
            >
              Billed yearly
            </ToggleButton>
          </ButtonGroup>
          <p className="pt-3 mb-0 text-success">
            <strong>Get 50% OFF for 12 months! Limited-time offer.</strong>
          </p>
        </div>
        <div className="row row-cols-1 row-cols-md-2 text-center mt-4 pricing-row">
          <Col>
            <PricingCard
              header="Individual"
              primary={false}
              discount={false}
              paidYearly={paidYearly}
              price={paidYearly ? '4.99' : '9.99'}
              buttonText="Go to the App Store"
              href="https://apps.apple.com/app/id1471892720"
              features={[
                'Only single user included',
                "Only user's device",
                'No team cooperation',
                'Simple chart',
                'Request for New Helicopter Model - Paid',
                '30 days free trial',
              ]}
            />
          </Col>
          <Col>
            <PricingCard
              header="Team"
              primary={true}
              discount={true}
              paidYearly={paidYearly}
              price={valueMonthly(1, paidYearly, priceMonthly, priceYearly)}
              priceDiscounted={valueMonthlyDiscounted(
                1,
                paidYearly,
                priceMonthly,
                priceYearly,
                coupon_percent_off
              )}
              buttonText="Try for FREE"
              href="/checkout"
              features={[
                'UNLIMITED users',
                'UNLIMITED devices',
                'Team cooperation within your organization',
                'Engine Condition Trend Monitoring',
                'Request for New Helicopter Model - Free',
                '30 days free trial',
              ]}
            />
          </Col>
        </div>
      </div>
    </Fragment>
  );
};

export default Pricing;
