export const valueMonthly = (amount, paidYearly, priceMonthly, priceYearly) => {
  const value = (
    amount *
    (paidYearly ? priceYearly / 12 : priceMonthly) *
    100
  ).toFixed(0);
  return value / 100;
};

export const valueMonthlyDiscounted = (
  amount,
  paidYearly,
  priceMonthly,
  priceYearly,
  coupon_percent_off
) => {
  const value = (
    amount *
    (paidYearly ? priceYearly / 12 : priceMonthly) *
    100
  ).toFixed(0);
  const discount = (value * (coupon_percent_off / 100)).toFixed(0);
  return (value - discount) / 100;
};

export const totalValue = (amount, price, coupon_percent_off) => {
  //Standard price
  const value = (amount * price * 100).toFixed(0);

  //Price with discount
  const discount = (value * (coupon_percent_off / 100)).toFixed(0);
  return (value - discount) / 100;
};

export const discountTotalValue = (
  amount,
  priceInCents,
  coupon_percent_off
) => {
  //Standard price
  const value = (amount * priceInCents).toFixed(0);

  //Price with discount
  const discount = (value * (coupon_percent_off / 100)).toFixed(0);
  return discount / 100;
};
