import './feature-price.styles.scss';
import { Fragment } from 'react';
import { Alert } from 'react-bootstrap';
import { AiOutlineCheckCircle } from 'react-icons/ai';

const FeaturePrice = () => {
  return (
    <Fragment>
      <Alert
        variant="secondary"
        className="d-flex flex-column align-items-center justify-content-center fs-6 flex-shrink-0 rounded-4 shadow-lg"
      >
        <p className="mb-0 fs-4 text-center">
          <AiOutlineCheckCircle className="mx-2 discount-icon" />
          Get <span className="discount-value">50%</span> OFF for 12 months
        </p>
        <p className="text-muted text-center">Limited-time offer</p>
        <p className="mb-0 fs-4 text-center">
          <AiOutlineCheckCircle className="mx-2 discount-icon" />
          No credit card required
        </p>
        <p className="text-muted text-center">Start with no obligation</p>
        <p className="mb-0 fs-4 text-center">
          <AiOutlineCheckCircle className="mx-2 discount-icon" />
          30 days free trial
        </p>
        <p className="text-muted text-center">Try the full capabilities</p>
      </Alert>
    </Fragment>
  );
};

export default FeaturePrice;
