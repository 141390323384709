import { Fragment } from 'react';
import ScreensRow from './screen-row.component';

const Screens = () => {
  return (
    <Fragment>
      <div className="px-4 py-5 text-center border-bottom">
        <h2 className="display-6 fw-bold lh-1">
          Helicopters.
          <i> Pilots.</i> Engines.
        </h2>
        <h2 className="display-7 fw-bold lh-1 business-color">
          Keep your company data in one place.
        </h2>
        <ScreensRow />
      </div>
    </Fragment>
  );
};

export default Screens;
