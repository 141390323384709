import { Fragment } from 'react';
import newPowerCheck from '../../assets/new-power-check.png';
import path from '../../assets/path-send.png';
import collectPowerChecks from '../../assets/collect-power-checks.png';
import { Col, Container, Row } from 'react-bootstrap';
import './screen-row.styles.scss';

const ScreensRow = () => {
  return (
    <Fragment>
      <Container>
        <Row className="align-items-center my-5" xs={1} md={1} lg={3}>
          <Col>
            <img
              src={newPowerCheck}
              alt="Pilots add power checks"
              // className="img-fluid"
              height="300"
              loading="lazy"
            />
          </Col>
          <Col>
            <div id="img-path">
              <img
                src={path}
                alt="Power checks are collected within Helitrends app"
                className="img-fluid my-4"
                width="350"
                loading="lazy"
              />
            </div>
          </Col>
          <Col>
            <img
              src={collectPowerChecks}
              alt="mockup"
              className="img-fluid my-4"
              width="400"
              loading="lazy"
            />
          </Col>
        </Row>
        <Row>
          <h4 className="fw-semibold mb-2">
            Say goodbye to the hassle of manually updating spreadsheets or
            documents
          </h4>
          <p className="text-muted">
            From your perspective as an individual user, you will gain access to
            power checks performed by other pilots from your company instantly.
            Therefore, you will be able to view not only your data but also the
            entire dataset for specified helicopters.
          </p>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ScreensRow;
