import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../../context/user.context';

const AdminRoute = ({ children }) => {
  const { currentUser } = useContext(UserContext);

  if (!currentUser) {
    return <Navigate to="/sign-in" />;
  }

  if (currentUser.email !== 'maciej.bielecki@gmail.com') {
    return <Navigate to="/sign-in" />;
  }

  return children;
};

export default AdminRoute;
