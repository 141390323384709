import { Container } from 'react-bootstrap';
import SignInForm from '../../components/sign-in-form/sign-in-form.component';
import appStoreBadge from '../../assets/appstore-badge.png';
import Contact from '../../components/contact/contact.component';

const SignIn = () => {
  return (
    <Container className="px-2 px-sm-4">
      <div className="px-2 py-5 text-center px-sm-4">
        <h1 className="fw-bold">Login in using your Helitrends account</h1>
        <div className="col-lg-8 mx-auto">
          <p className="lead mb-2">
            Your data is synchronized with your account in the mobile
            application, so you can log in using{' '}
            <strong>Helitrends account</strong>. If you are not a user of the
            Helitrends app, please sign up and download the app from the app
            store:
          </p>
          <a
            href="https://apps.apple.com/app/id1471892720"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={appStoreBadge}
              alt="App Store Badge links to Helitrends app"
              className="mb-4"
              width="150"
              loading="lazy"
            />
          </a>
        </div>
        <SignInForm />
      </div>
      <div className="border-top">
        <Contact />
      </div>
    </Container>
  );
};

export default SignIn;
