import { useState } from 'react';
import { Alert, Form, Button } from 'react-bootstrap';
import { signInAuthUserWithEmailAndPassword } from '../../utils/firebase/firebase.utils';
import FormInput from '../form-input/form-input.component';
import { Link, useNavigate } from 'react-router-dom';

const defaultFormFields = {
  email: '',
  password: '',
};

const SignInForm = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { email, password } = formFields;
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  console.log(formFields);

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setError('');
      setLoading(true);
      const { user } = await signInAuthUserWithEmailAndPassword(
        email,
        password
      );
      resetFormFields();
      console.log(user);
      navigate('/checkout');
    } catch (error) {
      console.log('user encountered an error', error);
      setError('Failed to sign in');
    }
    setLoading(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <div className="col-lg-6 mx-auto">
      {error && <Alert variant="danger">{error}</Alert>}
      <Form
        className="p-4 p-md-5 border rounded-3 bg-light"
        onSubmit={handleSubmit}
      >
        <FormInput
          label="Email address"
          controlID="floatingEmail"
          type="email"
          placeholder="name@example.com"
          required
          onChange={handleChange}
          name="email"
          value={email}
        />
        <FormInput
          label="Password"
          controlID="floatingPassword"
          type="password"
          placeholder="Password"
          required
          onChange={handleChange}
          name="password"
          value={password}
        />
        <Button
          disabled={loading}
          className="w-100 btn btn-lg btn-primary"
          type="submit"
        >
          Sign In
        </Button>
        <hr className="my-4" />
        <small className="text-muted">
          Don't have an account?
          <Link
            className="nav-link ps-1 fw-bold"
            to="/sign-up"
            style={{ display: 'inline' }}
          >
            Sign-up
          </Link>
        </small>
      </Form>
      <div className="w-100 text-center mt-3">
        <Link className="nav-link ps-1 text-muted" to="/forgot-password">
          Forgot Password?
        </Link>
      </div>
    </div>
  );
};

export default SignInForm;
