import { Fragment, useContext } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { ProductsContext } from '../../context/product.context';
import './feature-helicopter.styles.scss';
import { totalValue } from '../../utils/price.utils';

const FeatureHelicopter = () => {
  const minHelicopters = 1;
  const maxHelicopters = 500;
  const { products, setProduct } = useContext(ProductsContext);
  console.log(products);
  const { amount, paidYearly, priceMonthly, priceYearly, coupon_percent_off } =
    products;

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (Number(value) < event.target.min) {
      setProduct({ ...products, [name]: Number(event.target.min) });
      return;
    }
    if (Number(value) > event.target.max) {
      setProduct({ ...products, [name]: Number(event.target.max) });
      return;
    }
    setProduct({ ...products, [name]: Math.round(value) });
  };

  const toggleSwitch = () => {
    setProduct({ ...products, paidYearly: !paidYearly });
  };

  const handleIncrement = () => {
    if (products.amount < maxHelicopters) {
      setProduct({ ...products, amount: amount + 1 });
    }
  };

  const handleDecrement = () => {
    if (products.amount > minHelicopters) {
      setProduct({ ...products, amount: amount - 1 });
    }
  };

  return (
    <Fragment>
      <h3 className="fw-bold">Calculate your price</h3>
      <p className="text-muted">
        Our pricing is based on the number of helicopters you add to the
        platform, ensuring that you only pay for what you use.
      </p>
      <h6 className="fw-bold">Number of helicopters:</h6>
      <Form>
        <Row className="align-items-center mb-2">
          <Col xs="auto pe-0">
            <Form.Label htmlFor="inlineFormInput" visuallyHidden>
              Helicopters
            </Form.Label>
            <Form.Control
              // className="mb-2"
              name="amount"
              type="number"
              onChange={handleInputChange}
              value={amount}
              min={minHelicopters}
              max={maxHelicopters}
              step="1"
              style={{ minWidth: '150px' }}
            />
          </Col>
          <Col xs="auto pe-0">
            <Button variant="primary" onClick={handleIncrement}>
              +
            </Button>
          </Col>
          <Col xs="auto ps-2">
            <Button variant="secondary" onClick={handleDecrement}>
              -
            </Button>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col xs="auto" className="pe-0">
            <Form.Label style={{ marginBottom: '0.125rem' }}>
              Monthly
            </Form.Label>
          </Col>
          <Col xs="auto">
            <Form.Check
              type="switch"
              label="Annual billing"
              checked={paidYearly}
              onChange={toggleSwitch}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="auto">
            <p className="plan-price mt-2">
              <span className="text-muted">Total: </span>
              {totalValue(
                amount,
                paidYearly ? priceYearly : priceMonthly,
                coupon_percent_off
              )}
              <span className="reduced">
                {' '}
                USD/{paidYearly ? 'year' : 'month'}
              </span>
            </p>
            <p className="text-muted">
              The prices do not include VAT (Value Added Tax)
            </p>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

export default FeatureHelicopter;
