import { useState } from 'react';
import axios from 'axios';
import { useContext } from 'react';
import { Container, Button, Alert } from 'react-bootstrap';
import FeatureHelicopter from '../../components/feature/feature-helicopter.component';
import { ProductsContext } from '../../context/product.context';
import { UserContext } from '../../context/user.context';
import { BsCollection, BsCalendarCheck } from 'react-icons/bs';
import { TbDiscount2 } from 'react-icons/tb';
import { GiCancel } from 'react-icons/gi';
import Feature from '../../components/feature/feature.component';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import FeaturePrice from '../../components/feature/feature-price.component';
import Footer from '../../components/footer/footer.component';
import Contact from '../../components/contact/contact.component';

const Checkout = () => {
  const [searchParams] = useSearchParams();
  const subscriptionStatus = searchParams.get('status');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const { currentUser } = useContext(UserContext);
  const { products } = useContext(ProductsContext);
  const { amount } = products;

  useEffect(() => {
    if (subscriptionStatus === 'cancel') {
      setError('Failed to subscription processing');
    } else {
      setError('');
    }
  }, [subscriptionStatus]);

  const processRequest = () => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/business/request`, {
        email: currentUser?.email,
        helicopters: amount,
        sender: 'web',
      })
      .then((res) => {
        setError('');
        setSuccess(
          'Upon receiving your request, we will activate a FREE 30-day trial of Helitrends Teams for you. During this period, you will have full access to explore all the exciting new functionalities. We will notify you when your account status changes.'
        );
      })
      .catch((err) => {
        console.error(err.code);
        setError('Access request failure');
        setSuccess('');
      });
  };

  const handleCheckout = async (event) => {
    event.preventDefault();
    setLoading(true);
    processRequest();
    setLoading(false);
  };

  return (
    <Container className="px-4 py-5">
      {error && (
        <Alert variant="danger" className="mx-4">
          {error}
        </Alert>
      )}
      {success && (
        <Alert variant="success" className="mx-4">
          <Alert.Heading>Your request is processed</Alert.Heading>
          {success}
        </Alert>
      )}
      <div className="row row-cols-1 row-cols-md-2 align-items-md-center g-5 pb-5 border-bottom mx-0">
        <div className="col d-flex flex-column align-items-start gap-2">
          <FeatureHelicopter />
          <Button
            type="submit"
            className="mb-2 btn-lg"
            style={{ width: '100%' }}
            onClick={handleCheckout}
            disabled={loading}
          >
            Try for FREE
          </Button>
        </div>
        <div className="col">
          <div className="row row-cols-1 g-4">
            {/* <FeatureCard /> */}
            <FeaturePrice />
          </div>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-md-1 align-items-md-center g-5 py-5 mx-0">
        <h3 className="fw-bold">Licensing explained</h3>
      </div>
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 align-items-md-top g-5 pb-5 border-bottom mx-0">
        <Feature
          icon={<TbDiscount2 />}
          title="Discount 50% OFF"
          par="Buy subscription for your company and enjoy a special price. The used discount is active for 12 months."
        />
        <Feature
          icon={<BsCollection />}
          title="Get a 30-Day Free Trial"
          par="Unlock a possibilities with our 30-day free trial, you have the freedom to cancel anytime. Experience the true value we bring to your company."
        />
        <Feature
          icon={<BsCalendarCheck />}
          title="Monthly or Yearly"
          par="Consider your commitment level. If you expect to use the service for an extended period, a yearly subscription may be a better option. However, if you prefer flexibility, a monthly subscription allows you to start or cancel subscription at any time."
        />
        <Feature
          icon={<GiCancel />}
          title="Cancel anytime"
          par="Cancel subscription without facing any additional fees. Once the cancellation is processed, they will no longer be billed for future subscription periods. Note that any fees already paid are non-refundable, and the cancellation takes effect from the next billing cycle."
        />
      </div>
      <div className="border-bottom">
        <Contact />
      </div>
      <div className="row row-cols-1 row-cols-md-1 align-items-md-center g-5 py-5 mx-0">
        <div className="col">
          <h3 className="fw-bold mb-3">Any questions?</h3>
          <p>
            <a
              href="https://helitrends.com/privacy-policy-mobile.html"
              className="link-secondary"
            >
              Privacy policy
            </a>
            {' · '}
            <a
              href="https://helitrends.com/terms-of-use-mobile.html"
              className="link-secondary"
            >
              Terms of use
            </a>
          </p>
          <p>
            <a href="mailto: contact@helitrends.com" className="link-secondary">
              contact@helitrends.com
            </a>
          </p>
          <p>
            <a href="https://helitrends.com/" className="link-secondary">
              Helitrends website
            </a>
          </p>
        </div>
      </div>
      <Footer />
    </Container>
  );
};

export default Checkout;
