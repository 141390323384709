import { Container } from 'react-bootstrap';
import SignUpForm from '../../components/sign-up-form/sign-up-form.component';

const SignUp = () => {
  return (
    <Container className="px-2 px-sm-4">
      <div className="px-2 py-5 my-5 text-center px-sm-4">
        <h1 className="fw-bold">Expand the possibilities of your team</h1>
        <div className="col-lg-8 mx-auto">
          <p className="lead mb-4">
            Once you've completed this form, you’ll be able to see the
            difference Helitrends can make to your engines health.
            {/* Removed temporary: (... be able to) manage your company account and activate a 30-day free trial (to see the difference ...) */}
          </p>
        </div>
        <SignUpForm />
      </div>
    </Container>
  );
};

export default SignUp;
