import './feature.styles.scss';

const Feature = (feature) => {
  return (
    <div className="col d-flex flex-column gap-2">
      <div className="icon-square text-bg-light d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
        {feature.icon}
      </div>
      <h4 className="fw-semibold mb-0">{feature.title}</h4>
      <p className="text-muted">{feature.par}</p>
    </div>
  );
};

export default Feature;
