import { Fragment, useContext } from 'react';
import { Container, Nav, Navbar, Form, Button } from 'react-bootstrap';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/user.context';
import { signOutUser } from '../../utils/firebase/firebase.utils';
import logo from '../../assets/logo_business_teams.png';
import { HashLink } from 'react-router-hash-link';

const Navigation = () => {
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOutUser();
      navigate('/');
    } catch (error) {
      console.log('user logout encountered an error', error);
    }
  };

  return (
    <Fragment>
      <Navbar bg="light" expand="lg" sticky="top">
        <Container>
          <Navbar.Brand as={HashLink} to="/#top">
            <img src={logo} alt="logo" className="me-2" width="250" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Fragment>
                {/* PUBLIC LINKS */}
                <Nav.Link as={Link} to="https://www.helitrends.com">
                  Home
                </Nav.Link>
                <Nav.Link as={HashLink} to="/#trend">
                  Trend
                </Nav.Link>
                <Nav.Link as={HashLink} to="/#pricing">
                  Pricing
                </Nav.Link>
                <Nav.Link as={Link} to="/contact">
                  Contact
                </Nav.Link>
                <Nav.Link as={Link} to="https://blog.helitrends.com">
                  Blog
                </Nav.Link>
                {/* PRIVATE LINKS */}
                {currentUser ? (
                  <Fragment>
                    <Nav.Link as={HashLink} to="/checkout#top">
                      Checkout
                    </Nav.Link>
                    {/* <Nav.Link as={Link} to="/users">
                      Users
                    </Nav.Link>
                    <Nav.Link as={Link} to="/account">
                      Account
                    </Nav.Link> */}
                  </Fragment>
                ) : (
                  ''
                )}
              </Fragment>
            </Nav>
            {currentUser ? (
              <Form className="d-flex">
                <Button
                  variant="outline-primary"
                  className="me-2"
                  as={Link}
                  onClick={handleSignOut}
                >
                  Sign-out
                </Button>
              </Form>
            ) : (
              <Form className="d-flex">
                <Button
                  variant="outline-primary"
                  className="me-2"
                  as={Link}
                  to="/sign-up"
                >
                  Sign-up
                </Button>
                <Button variant="primary" as={Link} to="/sign-in">
                  Login
                </Button>
              </Form>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
    </Fragment>
    // <Fragment>
    //   <Container>
    //     <header
    //       className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom"
    //       sticky="top"
    //     >
    //       <Link
    //         to="/"
    //         className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none"
    //       >
    //         <img src={logoCorners} alt="logo" className="me-2" width="40" />
    //       </Link>
    //       <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
    //         <li>
    //           <Link to="/home" className="nav-link px-2 link-secondary">
    //             Home
    //           </Link>
    //         </li>
    //         <li>
    //           <Link to="/sign-in" className="nav-link px-2 link-dark">
    //             Sign-in
    //           </Link>
    //         </li>
    //         <li>
    //           <Link to="/sign-up" className="nav-link px-2 link-dark">
    //             Sign-up
    //           </Link>
    //         </li>
    //       </ul>
    //     </header>
    //   </Container>
    //   <Outlet />
    // </Fragment>
  );
};

export default Navigation;
